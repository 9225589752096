<template>
  <div class="carts">
    <div class="row">
      <div class="col">
        <div class="row">
          <div class="col-md-12 d-block d-sm-flex justify-content-between align-items-center">
            <h1 class="fw-600 fs-24 mb-0">Carrinhos</h1>
            <button class="btn btn-blue my-3 my-sm-0" data-toggle="modal" @click="openCreateModal()">
              Novo Carrinho
            </button>
          </div>
        </div>
        <div class="table-responsive mt-1">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Url</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="cart in carts.data" :key="cart.id">
                <td class="align-middle body-table">
                    {{ cart.id }}
                </td>
                <td class="align-middle body-table">
                    {{ cart.description }}
                </td>
                <td class="align-middle body-table">
                  <a :href="cart.url" target="_blank">{{ (cart.url) ? cart.url : '-' }}</a>
                </td>
                <td class="align-middle body-table">
                  <button class="btn mr-2" data-toggle="modal" @click="openUpdateModal(cart)">
                    <i class="far fa-edit"></i>
                  </button>
                  <button class="btn" data-toggle="modal" @click="openDeleteModal(cart)">
                    <i class="fas fa-times text-danger"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <LoadingComponent :loading="loading || !carts.data" />

    <!-- Register Modal -->
    <div class="modal fade" id="CreateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ (cart.id ? 'Editar Carrinho' : 'Cadastrar Carrinho')}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-md-12">
                    <label class="form-control-label">Nome:</label>
                    <input type="text" class="form-control" v-model="cart.description">
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-6">
                    <label class="form-control-label">Desconto:</label>
                    <money class="form-control weight" id="weight" v-model="cart.discount" v-bind="money"></money>
                  </div>
                  <div class="col-md-6">
                    <label class="form-control-label">Expiração:</label>
                    <input type="datetime-local" class="form-control" v-model="cart.expired_at">
                  </div>
                </div>
                <div class="mt-4 align-items-end" v-for="(product, index) in cart.products" :key="index">
                  <div class="row">
                    <div class="col-md">
                      <label class="form-control-label">Produto</label>
                      <select v-model="product.product_id" @change="updateProductSelection(index, product.product_id)" class="form-control">
                        <option :value="null">- Selecione -</option>
                        <option v-for="product_option in products" :key="product_option.id" :value="product_option.id">{{ product_option.title }}</option>
                      </select>
                    </div>
                    <div class="col-md-2">
                      <label class="form-control-label">Qtd.:</label>
                      <input type="number" class="form-control" v-model="product.quantity" min="1">
                    </div>
                    <div v-if="product.product" class="col-md-2">
                      <label class="form-control-label">Valor.:</label>
                      <p class="form-control-label align-item-center my-0 py-0">{{ product.product.price | money }}</p>
                      <p v-if="product.product.saleprice > 0" class="form-control-label my-0 py-0">Por: {{ product.product.saleprice | money }}</p>
                    </div>
                    <div class="col-md-1" v-if="cart.products.length > 1">
                      <button class="btn px-0 py-2 my-0 mx-0" @click="cart.products.splice(index, 1)">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="product.product" class="col mt-1">
                      <label class="form-control-label">Variação de produto</label>
                      <multiselect v-if="product.product.variants"
                          v-model="product.variant_id"
                          :options="product.product.variants.map(variant => variant.id)"
                          :multiple="false"
                          :custom-label="opt => product.product.variants.find(v => v.id === opt).description"
                          placeholder="Pesquise aqui"
                          track-by="id"
                          return="id"
                          deselectLabel="Clique na tag para remover"
                          selectLabel="Clique para selecionar"
                          selectedLabel="Selecionado"
                          label="description"
                          class="multiselect-style">
                          <span slot="noResult">Nenhuma variação foi encontrada.</span>
                      </multiselect>
                      <!-- <select v-model="product.variant_id" class="form-control">
                        <option :value="null">- Selecione -</option>
                        <option v-for="product_variant in product.product.variants" :key="product_variant.id" :value="product_variant.id">{{ product_variant.description }}</option>
                      </select> -->
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <button class="btn btn-outline-dark btn-block" @click="cart.products.push({ product_id: null, quantity: 1 })">
                      <i class="fas fa-plus mr-2"></i>Adicionar Item
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>
            <button type="button" class="btn btn-success" @click="updateCart()" v-if="cart.id">Editar carrinho</button>
            <button type="button" class="btn btn-success" @click="addCart()" v-else>Cadastrar carrinho</button>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETE MODAL -->
      <div class="modal fade" id="openDeleteModal" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog" role="document">
              <div class="modal-content">
                  <div class="modal-header">
                      <h3 class="modal-title" id="exampleModalLabel">Excluir carrinho</h3>
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div class="modal-body">
                      Deseja excluir o carrinho?
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-dark" data-dismiss="modal">Fechar</button>
                      <button type="button" class="btn btn-danger" @click="deleteCart()">Excluir</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'
import LoadingComponent from '../../components/layout/LoadingComponent'

export default {

  name: 'IndexCartsPage',

  components: {
    LoadingComponent,
    Multiselect
  },

  computed: mapState({
    products: state => state.params.data.products,
    carts: state => state.carts.data,
    loading: state => state.carts.loading
  }),

  data: function () {
    return {
      cart: {
        products: [
          {
            product: {},
            variant_id: null,
            quantity: 1
          }
        ]
      },
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      destroy_cart: null,
      variants: []
    }
  },

  methods: {

    ...mapActions('params', [
      'getProducts'
    ]),

    ...mapActions('carts', [
      'index',
      'store',
      'update',
      'destroy'
    ]),

    addCart: function () {
      this.store(this.cart)
      $('#CreateModal').modal('hide')
    },

    updateProductSelection (index, productId) {
      const originalProduct = this.products.find(p => p.id === Number(productId))

      if (originalProduct) {
        this.variants = originalProduct.variants
        // Criar uma cópia para evitar referência compartilhada
        this.$set(this.cart.products, index, {
          ...this.cart.products[index],
          product: JSON.parse(JSON.stringify(originalProduct)),
          variant: null // Resetar a variação ao trocar o produto
        })
      } else {
        this.cart.products[index].product = null
      }
    },

    openCreateModal: function () {
      this.cart = {
        description: null,
        discount: 0,
        expired_at: null,
        products: [
          {
            product: null,
            quantity: 1
          }
        ]
      }
      $('#CreateModal').modal()
    },

    openUpdateModal: function (cart) {
      this.cart = cart
      $('#CreateModal').modal()
    },

    openDeleteModal: function (cart) {
      this.destroy_cart = cart
      $('#openDeleteModal').modal()
    },

    updateCart: function () {
      this.update({ id: this.cart.id, data: this.cart })
      $('#CreateModal').modal('hide')
    },

    deleteCart: function () {
      this.destroy(this.destroy_cart.id)
      $('#openDeleteModal').modal('hide')
    }
  },

  mounted () {
    this.index({
      page: 1,
      name: null
    })
    this.getProducts()
  }

}

</script>
